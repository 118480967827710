import { Box, Tabs, Tab, Button } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useModule } from "../../../../core/services/Modules.service";
import { useAppSelector } from "../../../../core/store/hooks";
import { useAppContext } from "../../../contexts/AppContext";
import { TicketButton } from "../../UX/Ticket.button";
import { NewTicketModal } from "../dashboard/Tickets/NewTicket.modal";
import classNames from "classnames";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function a11yProps2(index: number) {
  return {
    id: `second-tab-${index}`,
    "aria-controls": `second-tabpanel-${index}`,
  };
}

export const RouteTabs = [
  {
    url: "/admin/modules/berths",
    label: "menu.modules.berth",
    module: "berth",
  },
  {
    url: "/admin/modules/electricity",
    label: "menu.modules.electricity",
    module: "electricity",
  },
  {
    url: "/admin/modules/vigie",
    label: "menu.modules.vigie",
    module: "vigie",
  },
  {
    url: "/admin/modules/handling-task",
    label: "menu.modules.crane",
    module: "handling-task",
  },
  {
    url: "/admin/modules/technical-areas",
    label: "menu.modules.technicalArea",
    module: "technical-areas",
  },

];

function replaceAreaUrl(tab: typeof RouteTabs, areaId: string = "all"): typeof RouteTabs {
  return tab.map((t: typeof RouteTabs[0]) => { return { ...t, url: t.url.replace("/modules", `/area/${areaId || "all"}/modules`) } })
}

// TODO : add responsive
// TODO : add config verification
export const TabMenu: FC = ({ children }) => {
  const [value, setValue] = useState<number | boolean>(0);
  const [value2, setValue2] = useState<number | boolean>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { areaId } = useParams();
  const { t } = useTranslation("app");
  const { toggleTicketModal } = useAppContext();

  const [tabList, setTabList] = useState<any[]>([]);

  const port = useAppSelector((state) => state.ports.current);
  const { isModuleActivated } = useModule();

  const ticketCount = useAppSelector((state) => state.tickets.list.filter(tick => tick.status !== FDK.Constants.TicketConstants.TicketStatus.CLOSED && tick.status !== FDK.Constants.TicketConstants.TicketStatus.ARCHIVED).length);
  const loading = useAppSelector(
    (state) =>
      state.tickets.listStatus === "loading" && state.tickets.firstLoading
  );

  useEffect(() => {
    if (port) {
      console.log("replaceAreaUrl", replaceAreaUrl(RouteTabs, areaId || "all").filter((tab) => {
        return isModuleActivated(tab.module, "overview")
      }));
      const list = replaceAreaUrl(RouteTabs, areaId || "all").filter((tab) => isModuleActivated(tab.module, "overview"))
      setTabList(list);
    }
  }, [port]);

  const openModal = () => {
    toggleTicketModal!();
  };

  useEffect(() => {
    // tab 1
    const findTab = (list: typeof tabList) => {
      const index = list.findIndex((r) => location.pathname.includes(r.url));
      if (index >= 0) {
        return index
      } else {
        return false
      }
    }

    setValue(findTab(tabList.slice(0, 4)))
    setValue2(findTab(tabList.slice(4)))

  }, [location, tabList]);

  return (
    <Box className=" shadow-md">
      <Box className="flex w-full place-items-end justify-between flex-wrap-reverse">
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={() => { }}
          aria-label="dashboard tabs menu"
          className="flex-wrap"
        >
          {tabList.map((tab, i) => {
            return (
              <Tab
                key={tab.url}
                onClick={(e) => {
                  navigate(tab.url);
                }}
                label={t(tab.label)}
                {...a11yProps(i)}
              />
            )
          })}
        </Tabs>
        {
          isModuleActivated("ticket") && <Box className="mx-4 flex gap-x-3 justify-center items-center">
            <TicketButton loading={loading} count={ticketCount} create={false} />
            <TicketButton onClick={openModal} create={true} />
          </Box>
        }


      </Box>

      <NewTicketModal />
    </Box>
  );
};
